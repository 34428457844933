var init = ($el) => {
    $el.find('label + input, label + textarea').each((key, el) => {
        var $input = $(el),
            $label = $input.prev().hide();

        $input.attr('placeholder', $label.text().replace(/(.*)\:.*/g, '$1'));

        if ($input.closest('.form-field').hasClass('form-field-required')) {
            $input.attr('placeholder', $input.attr('placeholder')+'*')
        }
    })
}

export default () => {
    if (!'placeholder' in document.createElement('input')) {
        return;
    }

    init($('body'));

    fg.events.on('edit:contact-form', (el) => {
        init($(el))
    })
}
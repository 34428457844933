var elements = [
    'table',
    //'.footer.twitter-feed'
].join(', ');

var update = ($wrapper, $container) => {
    var scrollHeight = $wrapper[0].scrollHeight,
        scrollLeft = $wrapper.scrollLeft(),
        scrollTop = $wrapper.scrollTop(),
        scrollWidth = $wrapper[0].scrollWidth,
        height = $wrapper.height(),
        width = $wrapper.width();

    $container.removeClass('more-content-left more-content-right more-content-top more-content-bottom');

    if (scrollWidth > width) {
        var maxHorizontalOffset = scrollWidth - width;

        $container
            .toggleClass('more-content-left', scrollLeft > 0)
            .toggleClass('more-content-right', scrollLeft < maxHorizontalOffset);
    }

    if (scrollHeight > height) {
        var maxVerticalOffset = scrollHeight - height;

        $container
            .toggleClass('more-content-top', scrollTop > 0)
            .toggleClass('more-content-bottom', scrollTop < maxVerticalOffset);
    }
};

var init = ($el) => {
    var $container = $el.wrap('<div class="scroll-wrapper"><div class="scroll-wrapper-inner"></div></div>').closest('.scroll-wrapper'),
        $wrapper = $container.find('> .scroll-wrapper-inner');

    $wrapper.on('scroll', () => update($wrapper, $container));
    $window.on('resize', () => update($wrapper, $container)).trigger('resize');
};

export default () => {
    $(elements).each((key, el) => init($(el)));

    fg.events.on('edit:text edit:detail', (el) => $(el).find(elements).each((key, el) => init($(el))))
}
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function (root, jQuery) {
            if (jQuery === undefined) {
                if (typeof window !== 'undefined') {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            factory(jQuery);
            return jQuery;
        };
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
    $.euphoriaMenu = function (options) {
        var defaults = {
                activeClassName: 'euphoria-menu-#{name}-active',
                debug: true,
                defaultName: 'main',
                listStaggerDelay: 80,
                menuSelector: '.euphoria-menu',
                menuButtonSelector: '.euphoria-menu-button',
                interactivity: true
            },
            settings = $.extend({}, defaults, options),
            $body = $('body'),
            $menus = $(settings.menuSelector),
            $menuButtons = $(settings.menuButtonSelector),
            logPrefix = '[euphoria-menu] ';

        function getActiveClassName(name) {
            return settings.activeClassName.replace('#{name}', name)
        }

        function isOpen(name) {
            return $body.hasClass(getActiveClassName(name))
        }

        function trigger(event, menuName) {
            $body.trigger(event, {
                name: menuName,
                options: settings
            })
        }

        function close(name) {
            trigger('euphoria-menu.close', name);

            $body
                .removeClass('euphoria-menu-active')
                .removeClass(getActiveClassName(name));

            $menuButtons.filter('[data-name="' + name + '"]')
                .removeClass('active');

            var $menu = $menus.filter('[data-name="' + name + '"]');
            $menu.removeClass('active');

            var $list = $menu.find(':not(li) > ul, > ul');
            closeList($list, true);

            if (settings.debug) {
                console.info(logPrefix + 'Close ' + name)
            }
        }

        function open(name) {
            trigger('euphoria-menu.open', name);

            $body
                .addClass('euphoria-menu-active')
                .addClass(getActiveClassName(name));

            $menuButtons.filter('[data-name="' + name + '"]')
                .addClass('active');

            var $menu = $menus.filter('[data-name="' + name + '"]');
            $menu.addClass('active');

            var $list = $menu.find(':not(li) > ul, > ul');
            openList($list, true);

            if (settings.debug) {
                console.info(logPrefix + 'Open ' + name)
            }
        }

        function openList($list, root) {
            if (!options.interactivity) {
                return;
            }

            $list
                .find('> *, .open > ul > *')
                .addClass('visible');

            if (!root) {
                $list
                    .hide()
                    .slideDown(240)
            }
        }

        function closeList($list, root) {
            if (!options.interactivity) {
                return;
            }

            $list
                .find('.visible')
                .removeClass('visible');

            if (!root) {
                $list
                    .show()
                    .slideUp(240)
            }
        }

        $menus.find('ul').each(function () {
            var count = 0;

            if (options.interactivity) {
                $(this).find('> li').addClass('animated').each(function () {
                    var delay = count++ * settings.listStaggerDelay + 'ms';

                    $(this)
                        .css('-webkit-transition-delay', delay)
                        .css('-moz-transition-delay', delay)
                        .css('-ms-transition-delay', delay)
                        .css('-o-transition-delay', delay)
                        .css('transition-delay', delay);
                })
            }
        });

        if ($(settings.menuSelector).length <= 0) {
            console.warn(logPrefix + 'No menus with class \'' + settings.menuSelector + '\'')
        }

        if ($(settings.menuButtonSelector).length <= 0) {
            console.warn(logPrefix + 'No menu buttons found with class \'' + settings.menuButtonSelector + '\'')
        }

        $menuButtons.each(function () {
            var $el = $(this);

            if ($menus.filter('[data-name="' + $el.data('name') + '"]').length <= 0) {
                console.warn(logPrefix + 'Menu button ' + $el.data('name') + ' doesn\'t have an associated menu')
            }
        });

        $body.on('click', settings.menuButtonSelector, function (e) {
            e.preventDefault();

            var $el = $(e.currentTarget),
                name = $el.data('name') || settings.defaultName;

            if (settings.debug) {
                console.info(logPrefix + 'Clicked button ' + name, $el[0])
            }

            if (isOpen(name)) {
                close(name)
            } else {
                open(name)
            }
        });

        if (options.interactivity) {
            $menus.find('.expand').on('click', function () {
                var $el = $(this),
                    $parent = $el.closest('li'),
                    $list = $parent.find('> ul');

                if ($parent.hasClass('open')) {
                    closeList($list)
                } else {
                    openList($list)
                }

                $parent.toggleClass('open')
            })
        }
    };
}));

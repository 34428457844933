import config from "../../config";
import forEach from "lodash/collection/forEach";
import filter from "lodash/collection/filter";
import inInitialViewport from "../../lib/functions/in-initial-viewport";

var map = [], matched = [];

var prepare = ($el, options) => {
    if ($el.hasClass('no-scroll-effect') || matched.indexOf($el[0]) >= 0) {
        return;
    }

    forEach(config.effects.scroll.elements, (options, selector) => {
        var parents = $el.parents(selector);

        if (parents.length >= 0) {
            parents.each((key, el) => matched.push(el))
        }
    });

    matched.push($el[0]);

    var $elements = $el;

    if (options.elements) {
        $elements = $el.find(options.elements.join(', '));
    }

    var entry = {
        $el: $el,
        elements: $elements,
        offset: $el.offset().top,
        visible: false,
        options: options
    };

    if ($window.scrollTop() + $window.height() > entry.offset) {
        entry.visible = true
    }

    if (!entry.visible) {
        $elements.css({opacity: 0});
    }

    map.push(entry)
};

var show = (entry, setCount) => {
    var display = entry.$el.css('display'),
        props = {
            delay: entry.options.delay || config.effects.scroll.animation.delay,
            duration: entry.options.duration || config.effects.scroll.animation.duration,
            stagger: entry.options.stagger || config.effects.scroll.animation.stagger
        };

    if (display == 'table') {
        props.display = 'table';
    }

    if (setCount && setCount > 0) {
        props.delay += props.stagger * setCount
    }

    entry.visible = true;

    var elements = entry.elements;

    if (entry.options.animateParent) {
        elements.add(entry.$el)
    }

    elements.velocity(config.effects.scroll.animation.effect, props)
};

export default () => {
    if (!config.effects.scroll || $body.hasClass('no-effects-scroll')) {
        return;
    }

    forEach(config.effects.scroll.elements, (options, selector) => {
        var $elements = $(selector);

        if (!$elements.length) {
            return
        }

        $elements.each((key, el) => prepare($(el), options))
    });

    $window.on('scroll resize', () => {
        var scrollOffset = $window.scrollTop() + $window.height();

        // Recalculate offset on each scroll as it could have changed
        // TODO: Figure out a more performant way of tracking changes
        forEach(map, (entry) => {
           entry.offset = entry.$el.offset().top
        });

        var entries = filter(map, (entry) => {
            return entry.offset < (scrollOffset) && entry.visible == false
        });

        forEach(entries, (entry, key) => show(entry, key));
    }).trigger('resize')
}

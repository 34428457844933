import config from "./config";
import FastClick from "fastclick";
import Velocity from "velocity-animate";
import VelocityUI from "velocity-animate/velocity.ui"
import "./lib/modernizr";

window.$ = jQuery;
$.fn.velocity = Velocity;

window.$window = $(window);
window.$body = $(document.body);

FastClick.attach(document.body);

require("./modules/slider").default();
require("./modules/effects/intro").default(config.effects.intro);
require("./modules/menu").default();
require("./modules/effects/scroll").default();
require("./modules/menu-responsive").default();
require("./modules/form-placeholder").default();
require("./modules/twitter").default();
require("./modules/scroll-wrapper").default();

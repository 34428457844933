import "../lib/euphoria/responsive/menu";

export default () => {
    $.euphoriaMenu({
        debug: false,
        interactivity: false
    });

    var $pageWrapper = $('#page-wrapper'),
        $body = $('body');

    $body.on('euphoria-menu.open', (e, params) => {
        var menuWidth =  $(`.responsive-menu[data-name="${params.name}"]`).width(),
            windowWidth = $window.width();

        if ((windowWidth - menuWidth) < 120 ) {
            return;
        }

        var scale = (windowWidth - menuWidth) / windowWidth,
            scroll = 100 * ($window.scrollTop() / ($body.height() - $window.height()));

        $pageWrapper.css('transform-origin', `0 ${scroll}%`).velocity({
            scale: scale
        }, {duration: 280});
    });

    $('body').on('euphoria-menu.close', () => {
        $pageWrapper.velocity({
            scale: 1
        }, {
            duration: 280,
            complete: () => {
                $pageWrapper.css({
                    transform: '',
                    transformOrigin: ''
                });
            }
        })
    })
}
import config from "../config";
import "../lib/owl.carousel2";
import "../lib/owl.carousel2.navigation";

var init = ($el) => {
    var $slider = $el.find('.slider');

    if ($slider.length) {
        $el = $slider
    }

    $el.addClass('owl-carousel').owlCarousel({
        items: 1,
        loop: false,
        center: true,
        mouseDrag: false,

        dots: false,
        nav: true,
        navText: ['', ''],
        autoplay: true,
        autoplayTimeout: 8000,
        autoplayHoverPause: true
    })

    if(/Trident\/|MSIE /.test(window.navigator.userAgent) && $el.closest('.fluid-columns').length) {
        $(window).on('resize', function () {
            $el
                .css({minHeight: 0})
                .css({
                    minHeight: $el.closest('.column').outerHeight()
                });
        })
    }
};

export default () => {
    $('.element-template--slider, .element-template--slider-big, .element-template--slider-huge').each((k, el) => init($(el)));

    fg.events.on('edit:detail edit:images', (el) => {
        var $el = $(el);

        if ($(el).hasClass('element-template--slider') || $(el).hasClass('element-template--slider-big') || $(el).hasClass('element-template--slider-huge')) {
            init($el)
        }
    })
}

import "../lib/euphoria/social/twitter";

var init = ($el) => {
    $el.euphoriaTwitter({
        count: $el.data('count') || 3,
        locale: fg.locale || 'en_US',
        search: !$el.data('user') ? $el.data('search') || null : null,
        user: $el.data('user') || null
    })
};

export default () => {
    $('.twitter-feed').each((key, el) => init($(el)));

    fg.events.on('edit:detail edit:text', (el) => {
        $(el).find('.twitter-feed').each((key, el) => init($(el)))
    })
}
import config from "../../config";
import defaults from "lodash/object/defaults";
import forEach from "lodash/collection/forEach";
import size from "lodash/collection/size";
import inInitialViewport from "../../lib/functions/in-initial-viewport";

export default (options) => {
    options = options || {};

    if (!size(options) || !options.elements || $body.hasClass('no-effects-intro')) {
        return;
    }

    var timeoutHandle;

    forEach(options.elements, (elements, selector) => {
        var $el = $(`${selector}:first-child`);

        if (!$el.length || !inInitialViewport($el)) {
            return;
        }

        var $subElements = $el.find(elements.join(', ')).css({opacity: 0});

        var animation = options.animation;

        $subElements.velocity(animation.effect, {
            delay: animation.delay || 600,
            duration: animation.duration || 500,
            stagger: animation.stagger || 800
        }, {
            complete: () => {
                if (timeoutHandle) {
                    clearTimeout(timeoutHandle)
                }

                timeoutHandle = setTimeout(() => {
                    $body.addClass('no-effects-intro');
                    $body.removeClass('effects-intro')
                }, 500)
            }
        })
    });

    setTimeout(() => {
        if(!timeoutHandle) {
            timeoutHandle = setTimeout(() => {
                $body.addClass('no-effects-intro');
                $body.removeClass('effects-intro')
            }, 500)
        }
    }, (options.animation.delay || 600) + 100)
}

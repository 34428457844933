/*
 $.euphoriaTwitter

 Availabe options :
 user: 'twitter',		Name of users timeline we wish to display
 count : 3,          	Number of tweets displayed
 images : 0,				Boolean, use image thumbs
 timeline : 1,           Boolean, use someones timeline or search for tweets
 subject : '#debug'      String, search text, can be a mention(@) or a hashtag(#), multiple queries allowed
 */
(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function (root, jQuery) {
            if (jQuery === undefined) {
                if (typeof window !== 'undefined') {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            factory(jQuery);
            return jQuery;
        };
    } else {
        // Browser globals
        factory(jQuery);
    }
}(function ($) {
    $.fn.euphoriaTwitter = function (options) {
        // Set the default values, use comma to separate the settings, example:
        var defaults = {
            url: '/-fg-services?s=twitter',
            user: 'twitter',
            search: null, //'twitter',
            count: 3,
            locale: 'en_US',
            retweets: false,
            template: function (tweet, funcs) {
                var originalTweet = tweet,
                    time = function () {
                        var timeAgo = funcs.timeAgo(originalTweet.created_at);

                        return originalTweet.retweeted
                            ? __('Retweeted :time_ago').replace(/:time_ago/, timeAgo)
                            : timeAgo;
                    };

                if (tweet.retweeted) {
                    tweet = tweet.retweeted_status
                }

                var html = '<a href="http://twitter.com/' + tweet.user.screen_name + '" target="_blank" class="user"><img src="' + tweet.user.profile_image_url + '" class="profile" /><span class="name">' + tweet.user.name + '</span><span class="handle">@' + tweet.user.screen_name + '</span></a>';
                html += '<div class="text">' + funcs.clean(tweet.text) + '</span><time>' + time() + '</time>';

                return html
            }
        };

        options = $.extend(defaults, options);

        /**
         * Translate a string using the languages stored in $.fn.twitter.locale
         */
        var __ = function (string) {
            var l = $.euphoriaTwitter.locale[options.locale] || undefined;

            if (l !== undefined) {
                return l[string] || string
            }

            return string
        };

        /**
         * relative time calculator FROM TWITTER
         * @param {string} twitter date string returned from Twitter API
         * @return {string} relative time like "2 minutes ago"
         */
        var timeAgo = function (dateString) {
            var rightNow = new Date(),
                then = new Date(dateString),
                diff = rightNow - then;

            var second = 1000, minute = second * 60, hour = minute * 60, day = hour * 24, week = day * 7;

            if (isNaN(diff) || diff < 0)
                return '';

            if (diff < second * 2) {
                return __('just now')
            }

            if (diff < minute) {
                return __(':amount seconds ago').replace(/:amount/, Math.floor(diff / second))
            }

            if (diff < minute * 2) {
                return __('a minute ago')
            }

            if (diff < hour) {
                return __(':amount minutes ago').replace(/:amount/, Math.floor(diff / minute))
            }

            if (diff < hour * 2) {
                return __('an hour ago')
            }

            if (diff < day) {
                return __(':amount hours ago').replace(/:amount/, Math.floor(diff / hour))
            }

            if (diff > day && diff < day * 2) {
                return __('yesterday')
            }

            if (diff < day * 365) {
                return __(':amount days ago').replace(/:amount/, Math.floor(diff / day))
            }

            return __('over a year ago')
        };

        /**
         * The Twitalinkahashifyer!
         * http://www.dustindiaz.com/basement/ify.html
         * Eg:
         * ify.clean('your tweet text');
         */
        var link = function (tweet) {
            return tweet.replace(/\b(((https*\:\/\/)|www\.)[^\"\']+?)(([!?,.\)]+)?(\s|$))/g, function (link, m1, m2, m3, m4) {
                var http = m2.match(/w/) ? 'http://' : '';
                return '<a class="twtr-hyperlink" target="_blank" href="' + http + m1 + '">' + ((m1.length > 25) ? m1.substr(0, 24) + '...' : m1) + '</a>' + m4
            })
        };

        var at = function (tweet) {
            return tweet.replace(/\B[@]([a-zA-Z0-9_]{1,20})/g, function (m, username) {
                return '<a target="_blank" class="twtr-atreply" href="http://twitter.com/intent/user?screen_name=' + username + '">@' + username + '</a>'
            })
        };

        var list = function (tweet) {
            return tweet.replace(/\B[@ï¼ ]([a-zA-Z0-9_]{1,20}\/\w+)/g, function (m, userlist) {
                return '<a target="_blank" class="twtr-atreply" href="http://twitter.com/' + userlist + '">@' + userlist + '</a>';
            })
        };

        var hash = function (tweet) {
            return tweet.replace(/(^|\s+)#(\w+)/gi, function (m, before, hash) {
                return before + '<a target="_blank" class="twtr-hashtag" href="http://twitter.com/search?q=%23' + hash + '">#' + hash + '</a>';
            })
        };

        var clean = function (tweet) {
            tweet = link(tweet);
            tweet = list(tweet);
            tweet = at(tweet);
            tweet = hash(tweet);

            return tweet
        };

        var templateFunctions = {
            clean: clean,
            timeAgo: timeAgo
        };

        return this.each(function () {
            var $el = $(this),
                data = {
                    include_rts: options.retweets,
                    count: options.count,
                    include_entities: true
                };

            if (options.user) {
                data.r = 'user_timeline';
                data.screen_name = options.user
            } else {
                data.r = 'search';
                data.q = options.search
            }

            $.ajax({
                url: options.url,
                type: 'GET',
                dataType: 'json',
                data: data,

                success: function (data) {
                    var html = '<ul>';

                    for (var i = 0; i < data.length; i++) {
                        html += '<li class="tweet ' + (data[i].retweeted ? 'retweet' : '') + '">';
                        html += options.template(data[i], templateFunctions);
                        html += '</li>'
                    }

                    html += '</ul>';

                    $el
                        .trigger('twitter.before-load', data)
                        .html(html)
                        .trigger('twitter.after-load', data)
                }
            });
        })
    };

    $.euphoriaTwitter = {};
    $.euphoriaTwitter.locale = {
        'de_DE': {
            'just now': 'soeben',
            ':amount seconds ago': 'vor :amount Sekunden'
        },
        'nl_NL': {
            'just now': 'zojuist',
            ':amount seconds ago': ':amount seconden geleden',
            'a minute ago': 'een minuut geleden',
            ':amount minutes ago': ':amount minuten geleden',
            'an hour ago': 'een uur geleden',
            ':amount hours ago': ':amount uur geleden',
            'yesterday': 'gisteren',
            ':amount days ago': ':amount dagen geleden',
            'over a year ago': 'meer dan een jaar geleden',
            'Retweeted :time_ago': ':time_ago geretweet'
        }
    }
}));

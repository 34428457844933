var sidebarMenu = false,
    sidebarMenuActive = false;

var expand = (e, $el, mouse) => {
    if ((mouse && (sidebarMenuActive || $el.closest('.responsive-menu').length)) || $el.hasClass('open')) {
        return;
    }

    $el.find('> ul').velocity('stop').velocity('slideDown', mouse ? 140 : 280);
    $el.addClass('open');
};

var collapse = (e, $el, mouse) => {
    if ((mouse && (sidebarMenuActive  || $el.closest('.responsive-menu').length)) || !$el.hasClass('open')) {
        return;
    }

    $el.find('> ul').velocity('stop').css('height', 'auto').velocity('slideUp', mouse ? 140 : 280);
    $el.removeClass('open');
};

var toggle = (e, $el) => {
    e.preventDefault();

    ($el.hasClass('open')
        ? collapse
        : expand)(e, $el)
};

export default () => {
    sidebarMenu = !$('body').hasClass('menu-orientation-top');

    if (sidebarMenu) {
        $window.on('resize', () => {
            sidebarMenuActive = $window.width() >= 1170;

            var $menu = $('.mod-menu');

            $menu.each((key, el) => {
                if ($(el).parents('.responsive-menu').length) {
                    return;
                }

                $(el).find('.active')
                    .toggleClass('open', sidebarMenuActive)
                    .find('> ul').css('display', '')
            })

        }).trigger('resize');
    }

    $('.responsive-menu .mod-menu .active')
        .addClass('open');

    $('.mod-menu.element-template--default')
        .on('click', '.expand', (e) => toggle(e, $(e.currentTarget).closest('li')))
        .on('mouseenter', 'li', (e) => expand(e, $(e.currentTarget), true))
        .on('mouseleave', 'li', (e) => collapse(e, $(e.currentTarget), true))
}

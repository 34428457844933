export default {
    effects: {
        intro: {
            animation: {
                delay: 400,
                duration: 500,
                effect: 'transition.slideUpIn',
                stagger: 800
            },
            elements: {
                '.element-template--slider': ['.slide:first-child .effect-wrapper'],
                '.element-template--slider-big': ['.slide:first-child .effect-wrapper'],
                '.element-template--slider-huge': ['.slide:first-child .effect-wrapper']
            }
        },
        scroll: {
            animation: {
                delay: 120,
                duration: 400,
                effect: 'transition.slideUpIn',
                stagger: 150
            },
            elements: {
                '.columns': {
                    elements: ['.column']
                },
                '.item': {},
                '.fragment': {},
                '.footer-row.info, .footer-row.newsletter': {
                    delay: 0,
                    elements: ['.column']
                },
                '.footer-row': {
                    delay: 0
                }
            }
        }
    }
}